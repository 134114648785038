export class ModulesAccess {
  moduleID: number=0;
  userID: number = 0;
  groupID: number = 0;
  allowView: boolean = false;
  allowModify: boolean = false;
  allowDelete: boolean = false;
  exclude: boolean = false;
  status: string = '';
}
