
import { ClientObject } from '../_models/client';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ccworksheetsObject } from '../_models/ccworksheets';
import { GenericService } from './generic.service';
import { DatePipe } from '@angular/common';



@Injectable()
export class ProductsAcceptanceService {

  private _Url: string = "/api/products/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "products";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService,
    private genericService: GenericService,) {

  }




  // NEED THIS FOR ACCEPTANCE PAGE, CUSTOMIZE IT WITH YOUR FORM FIELDS
  //acceptancePage_paandrepatriationwithgesyform10
  async acceptancePage_paandrepatriationwithgesyform10(formObject, lang) {

    //get the occupation and nationality codes

    //console.log('testing');

    var datePipe = new DatePipe("en-CY");
    var occupation = await this.genericService.getOccupationByLanguage(lang, formObject['formValues']['OccupationSyCode']);
    occupation = lang == 'en' ? occupation[0]['Description'] : occupation[0]['GrDescription']

    //get certificate language
    //var lang = formObject['CertificateLanguage'];

    //START the report data

    var createHTMLMainTitle = '';// + await this.genericService.useTranslation('EMPLOYER\'S LIABILITY INSURANCE CERTIFICATE')  + '</b></h2>'; //usually certificate name
    var createHTMLTitle = '<h3>' + await this.genericService.useTranslation(formObject['formValuesExtras']['productOptionName'], lang) + '</h3>';
    var createHTMLTitle2 = '<h3 align="right">' + await this.genericService.useTranslation('Premium', lang) + ' : ' + formObject['product']['Price'] + '€' + ' </h3>'

    if (formObject['formValuesExtras']['productOptionValue'] == 'HouseKeeper') {
      createHTMLMainTitle = '<h2 align="center"><b>' + await this.genericService.useTranslation('SCHEDULE OF BENEFITS PA AND MEDICAL INSURANCE FOR FOREIGNERS / INVOICE', lang) + '</b></h2>';
    }
    else if (formObject['formValuesExtras']['productOptionValue'] == 'HouseKeeperEE') {
      createHTMLMainTitle = '<h2 align="center"><b>' + await this.genericService.useTranslation('ΠΙΝΑΚΑΣ ΑΣΦΑΛΙΣΤΗΡΙΟΥ Π.Α. ΑΛΛΟΔΑΠΩΝ ΚΑΙ ΕΥΘΥΝΗΣ ΕΡΓΟΔΟΤΗ / ΤΙΜΟΛΟΓΙΟ', lang) + '</b></h2>';
    }
    else if (formObject['formValuesExtras']['productOptionValue'] == 'OtherOccupations') {
      createHTMLMainTitle = '<h2 align="center"><b>' + await this.genericService.useTranslation('SCHEDULE OF BENEFITS PA AND MEDICAL INSURANCE FOR FOREIGNERS / INVOICE', lang) + '</b></h2>';
    }

    let values = [

      { Tag: 'Start', Type: 'HTMLText', Title: "" },
      { Tag: 'Row', Type: 'HTMLText', Display: 'ALL', ColumnName: "", Data: createHTMLMainTitle },
      { Tag: 'Row', Type: 'HTMLTextDouble', Display: 'ALL', ColumnName: "", Data: '', Data2: createHTMLTitle2 },
      { Tag: 'End', Type: 'HTMLText' },
    ]



    //if (this.f.productOptions.value == 'HouseKeeperEE') {

    //  if (this.genericService.translate.currentLang == 'el') {

    //    var createHTML = `<p>Ο περί Υποχρεωτικής Ασφάλισης της Ευθύνης των Εργοδοτών Νόμος (Νόμος 174 του 1989) ή οποιοσδήποτε νόμος τον
    //  τροποποιεί ή τον αντικαθιστά.<br><br>
    //  (Αντίγραφο του παρόντος πιστοποιητικού πρέπει να εκτίθεται σε κάθε χώρο εργασίας στον οποίο ο ασφαλισμένος
    //  εργοδότης απασχολεί πρόσωπα που καλύπτονται από την ασφάλιση αυτή)</p>`;

    //    values.push(
    //      { Tag: 'Start', Type: 'HTMLText', Title: "" },
    //      { Tag: 'Row', Type: 'HTMLText', Display: 'ALL', ColumnName: "", Data: createHTML },
    //      { Tag: 'End', Type: 'HTMLText' },
    //    );
    //  }
    //  else {
    //    var createHTML = `<p>Ο περί Υποχρεωτικής Ασφάλισης της Ευθύνης των Εργοδοτών Νόμος (Νόμος 174 του 1989) ή οποιοσδήποτε νόμος τον
    //  τροποποιεί ή τον αντικαθιστά.<br><br>
    //  (Αντίγραφο του παρόντος πιστοποιητικού πρέπει να εκτίθεται σε κάθε χώρο εργασίας στον οποίο ο ασφαλισμένος
    //  εργοδότης απασχολεί πρόσωπα που καλύπτονται από την ασφάλιση αυτή)</p>`;

    //    values.push(
    //      { Tag: 'Start', Type: 'HTMLText', Title: "" },
    //      { Tag: 'Row', Type: 'HTMLText', Display: 'ALL', ColumnName: "", Data: createHTML },
    //      { Tag: 'End', Type: 'HTMLText' },
    //    );
    //  }



    //}


    values.push(
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("INSURED’S DETAILS", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("First Name", lang), Data: formObject['selectedClient']['FirstName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Last Name", lang), Data: formObject['selectedClient']['LongDescription'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card", lang), Data: formObject['selectedClient']['IdentityCard'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValuesExtras']['productOptionValue'] == 'HouseKeeperEE') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Employer S.I.N", lang), Data: formObject['formValues']['employersin'] },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("INSURED DETAILS", lang) },
      /*      { Tag: 'Row', Type: 'Section', Display: (this.f.productOptions.value == 'HouseKeeperEE') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Insured Amount"), Data: '€' + this.f.insuredAmount.value },*/
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Full Name", lang), Data: formObject['formValues']['firstName'] + ' ' + formObject['formValues']['lastName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card / Passport", lang), Data: formObject['formValues']['identityCardOrPassport'] },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Last Name"), Data: this.f.lastName.value },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Nationality", lang), Data: formObject['formValuesExtras']['nationalityDescription'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Occupation", lang), Data: occupation.toString() },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Birth Date", lang), Data: datePipe.transform(formObject['formValues']['birthDate'], 'dd/MM/yyyy') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Age", lang), Data: formObject['formValues']['age'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Gender", lang), Data: formObject['formValuesExtras']['genderDescription'] },
      //{ Tag: 'Row', Type: 'Section', Display: true, ColumnName: await this.genericService.useTranslation("Passport"), Data: this.f.passport.value },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Postal Code"), Data: this.f.postalCode.value },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Address"), Data: this.f.address.value },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Street No"), Data: this.f.streetNo.value },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Apartment No."), Data: this.f.appartmentNo.value },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Community"), Data: this.f.community.value },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("District"), Data: this.f.district.value },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Country"), Data: this.f.country.value },
      {
        Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Address", lang),
        Data: formObject['formValues']['postalCode'] + ', ' + formObject['formValues']['address'] + ', ' + formObject['formValues']['streetNo'] + ', ' + formObject['formValues']['appartmentNo'] + ', ' + formObject['formValues']['community'] + ', ' + formObject['formValues']['district'] + ', ' + formObject['formValues']['country']
      },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("PERIOD OF INSURANCE", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Starting Date", lang), Data: await this.genericService.useTranslation("From", lang) + ' ' + datePipe.transform(formObject['formValues']['policyStartingDate'], 'dd/MM/yyyy') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Expiry Date", lang), Data: await this.genericService.useTranslation("To midnight of", lang) + ' ' + datePipe.transform(formObject['formValues']['policyExpiryDate'], 'dd/MM/yyyy') },
      { Tag: 'End', Type: 'Section' },
    );


    values.push(
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("TABLE OF BENEFITS", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("1. Death by Accident", lang), Data: '5000€' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("2. Transportation of corpse until", lang), Data: '3500€' },
      { Tag: 'End', Type: 'Section' },
    );


    //add ofelimata    
    if (formObject['formValuesExtras']['productOptionValue'] == 'HouseKeeperEE' || formObject['formValuesExtras']['productOptionValue'] == 'HouseKeeper' || formObject['formValuesExtras']['productOptionValue'] == 'OtherOccupations') {

      if (lang == 'el') {

        if (formObject['formValuesExtras']['productOptionValue'] == 'HouseKeeperEE') {
          values.push(
            { Tag: 'Start', Type: 'Section', Title: "ΚΑΛΥΨΗ ΕΥΘΥΝΗΣ ΕΡΓΟΔΟΤΗ" },
            { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: "Όρια Κάλυψης", Data: '' },
            { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: "Α. Όριο Ευθύνης για κάθε εργοδοτούμενο", Data: '160,000€' },
            { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: "Β. Όριο Ευθύνης για κάθε περιστατικό ή σειρά περιστατικών", Data: '3,415,000€' },
            { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: "Γ. Συνολικό Όριο Ευθύνης για οποιαδήποτε περίοδο ασφάλισης", Data: '5,125,000€' },
            { Tag: 'End', Type: 'Section' },
          );

          values.push(
            { Tag: 'Start', Type: 'Section', Title: "" },
            { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: "Γενική Περιγραφή καθηκόντων των εργοδοτουμένων", Data: 'Υπολογιζόμενο ολικό ποσό Ακαθάριστων Απολαβών πάνω στο οποίο βασίζεται το Ασφάλιστρο' },
            { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: "Οικιακή Βοηθός", Data: formObject['formValues']['insuredAmount'] + '€' },
            { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: "ΣΥΝΟΛΟ :", Data: formObject['formValues']['insuredAmount'] + '€' },
            { Tag: 'End', Type: 'Section' },
          );
        }

        var createHTML = '<p font-size="38px"><b><u>1020-CL-PAFF-END RENEWAL CLAUSE</u></b></p>';
        createHTML += `<p>Δια της παρούσης συμφωνείται ότι νοουμένου δεν υπάρξει οποιαδήποτε αλλαγή στον ασφαλισμένο κίνδυνο, η οποία μπορεί να επηρεάσει την
        απόφαση της Εταιρείας για παροχή ασφάλισης, προτιθέμεθα να ανανεώνουμε αυτόματα το παρόν ασφαλιστήριο, διατηρώντας πάντα το δικαίωμα αναθεώρησης του ασφαλίστρου
        ή και των όρων του ασφαλιστηρίου.</p>`;

        values.push(
          { Tag: 'Start', Type: 'HTMLText', Title: "<br><br>ΔΕΣΜΕΥΤΙΚΟΙ ΟΡΟΙ ΚΑΙ ΡΗΤΡΕΣ" },
          { Tag: 'Row', Type: 'HTMLText', Display: 'ALL', ColumnName: "", Data: createHTML },
          { Tag: 'End', Type: 'HTMLText' },
        );
      }
      else {
        var createHTML = '<p font-size="38px"><b><u>1020-CL-PAFF-END RENEWAL CLAUSE</u></b></p>';
        createHTML += `<p>It is agreed , that provided there is no other change in the insured risk ,which may influence the decision of the company to
        provide cover, we intend to automatically renew the said policy , always with the option for adjusting the premium and/or terms of the policy.</p>`;

        values.push(
          { Tag: 'Start', Type: 'HTMLText', Title: "<br><br>CONDITION AND WARRANTIES" },
          { Tag: 'Row', Type: 'HTMLText', Display: 'ALL', ColumnName: "", Data: createHTML },
          { Tag: 'End', Type: 'HTMLText' },
        );
      }



    }

    //add back the user defined language
    //this.genericService.translate.use(defaultLangSaved);



    return values;

  }

  async acceptancePage_covernoteform(formObject, lang) {
    var datePipe = new DatePipe("en-CY");
    //console.log(formObject)
    //var lang = formObject['formValues']['CertificateLanguage'];
    //console.log(formObject['formValues']['CarWeight'].toString())
    let values = [
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product", lang), Data: formObject['product'][lang == 'el' ? 'TitleGR' : 'Title'] },
      /*{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product Description", lang), Data: formObject['product']['Description'] },*/
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Major Category", lang), Data: formObject['product']['MajorCategory'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Insurance Type", lang), Data: formObject['formValues']['insuranceType'] },
      /*{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Price", lang), Data: formObject['product']['Price'] },*/
      { Tag: 'End', Type: 'Section' },
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Client", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("First Name", lang), Data: formObject['selectedClient']['FirstName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Last Name", lang), Data: formObject['selectedClient']['LongDescription'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card", lang), Data: formObject['formValues']['ClientIdentityCard'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Gender", lang), Data: (formObject['formValues']['ClientGender'] == null) ? '' : lang == 'el' ? (formObject['formValues']['ClientGender'] == 'M' ? 'Άντρας' : 'Γυναίκα') : (formObject['formValues']['ClientGender'] == 'M' ? 'Male' : 'Female') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Date of Birth", lang), Data: (formObject['formValues']['ClientBirthDate'] == "" || formObject['formValues']['ClientBirthDate']==null) ? '': datePipe.transform(formObject['formValues']['ClientBirthDate'], 'dd/MM/yyyy') },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Licence Date", lang), Data: datePipe.transform(formObject['formValues']['ClientLicenceDate'], 'dd/MM/yyyy') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Licence Type", lang), Data: await this.genericService.useTranslation(formObject['formValues']['LicenceCountry'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['LicenceCountry'] == 'Other Licence') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Other Licence Type", lang), Data: formObject['formValues']['LicenceCountryOther'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Nationality", lang), Data: formObject['formValues']['ClientNationalitySyCode'][(lang == 'el' ? 'GrDescription' : 'Description')] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Telephone", lang), Data: formObject['formValues']['ClientTelephone'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Is learner driver", lang), Data: await this.genericService.useTranslation(formObject['formValues']['IsLearner'], lang) },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Coverage Period", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Starting Date", lang), Data: datePipe.transform(formObject['formValues']['CoverStartingDate'], 'dd/MM/yyyy') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Expiry Date", lang), Data: datePipe.transform(formObject['formValues']['CoverExpiryDate'], 'dd/MM/yyyy') },
      { Tag: 'End', Type: 'Section' },


      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Motor Details", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover", lang), Data: await this.genericService.useTranslation(formObject['formValues']['CoverSelection'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Package", lang), Data: formObject['formValues']['PackageSelection'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Any Driver", lang), Data: await this.genericService.useTranslation((formObject['formValues']['AnyDriver'] ? 'Yes' : 'No'), lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Owner as Driver", lang), Data: await this.genericService.useTranslation(formObject['formValues']['OwnerAsDriver'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Registration Number", lang), Data: formObject['formValues']['RegistrationNumber'] },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Make", lang), Data: formObject['formValues']['CarMake'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Model", lang), Data: formObject['formValues']['CarModel'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Body Type", lang), Data: formObject['formValues']['CarBodyType'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cubic Capacity", lang), Data: formObject['formValues']['CarCubicCapacity'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("High Performance", lang), Data: await this.genericService.useTranslation(formObject['formValues']['HighPerformance'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Electric Vehicle", lang), Data: await this.genericService.useTranslation(formObject['formValues']['ElectricVehicle'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Weight", lang), Data: (formObject['formValues']['CarWeight'].toString().trim() == "" ? "0" : formObject['formValues']['CarWeight']) },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Type of Roof", lang), Data: await this.genericService.useTranslation(formObject['formValues']['typeOfRoof'], lang) },
      
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Manufacture Year", lang), Data: formObject['formValues']['CarManufactureYear'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Passengers", lang), Data: formObject['formValues']['CarPassengers'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Vehicle Use", lang), Data: await this.genericService.useTranslation(formObject['formValues']['VehicleUse'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Steering Position", lang), Data: await this.genericService.useTranslation(formObject['formValues']['steeringPosition'], lang) },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Insured Amount", lang), Data: "€ " + formObject['formValues']['InsuredAmount'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Vehicle Value", lang), Data: "€ " + formObject['formValues']['vehicleValue'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Excess", lang), Data: "€ " + (formObject['formValues']['Excess'] == null ? '0' : formObject['formValues']['Excess']) },

      { Tag: 'End', Type: 'Section' },

    ]

    var driversListCount = 1;
    //console.log(formObject['formValues']['driversData']);

    for (var item of formObject['formValues']['driversData']) {

      values.push(

        { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Driver", lang) + " " + driversListCount },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Full Name", lang), Data: item['fullName'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Date of Birth", lang), Data: datePipe.transform(item['dateOfBirth'], 'dd/MM/yyyy') },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Licence Date", lang), Data: datePipe.transform(item['licenceDate'], 'dd/MM/yyyy') },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card No.", lang), Data: item['identityCardNo'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Full / Learner", lang) + " " + await this.genericService.useTranslation("Licence", lang), Data: await this.genericService.useTranslation((item['fullOrLearner']), lang) },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Country of Origin", lang), Data: item['countryOfOrigin'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Claims", lang), Data: item['claims'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Conv. or Points", lang), Data: item['convOrPoints'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Nationality", lang), Data: item['nationality'] },
        { Tag: 'End', Type: 'Section' },
      );
      driversListCount++;
    }
    values.push(
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Other/Alterations", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Change of Vehicle", lang), Data: await this.genericService.useTranslation((formObject['formValues']['ChangeOfVehicle'] ? 'Yes' : 'No'), lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Change of Owner", lang), Data: await this.genericService.useTranslation((formObject['formValues']['ChangeOfOwner'] ? 'Yes' : 'No'), lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Addition/Deletion of Authorised Driver", lang), Data: await this.genericService.useTranslation((formObject['formValues']['AdditionDeletionOfAuthDriver'] ? 'Yes' : 'No'), lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("New Policy", lang), Data: await this.genericService.useTranslation((formObject['formValues']['NewPolicy'] ? 'Yes' : 'No'), lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Other", lang), Data: await this.genericService.useTranslation((formObject['formValues']['OtherSelected'] ? 'Yes' : 'No'), lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Details", lang), Data: formObject['formValues']['OtherDetails'] },

    )
    return values;

  }
  async acceptancePage_fireandrelatedform(formObject, lang) {

    var datePipe = new DatePipe("en-CY");
    //console.log('formObject lang : ', formObject['CertificateLanguage']);

    //get certificate language
    //var lang = formObject['CertificateLanguage'];
    var occupation = await this.genericService.getOccupationByLanguage(lang, formObject['formValues']['OccupationSyCode']);
    occupation = lang == 'en' ? occupation[0]['Description'] : occupation[0]['GrDescription']
    let values = [

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product", lang), Data: formObject['product']['Title'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product Description", lang), Data: formObject['product']['Description'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Major Category", lang), Data: formObject['product']['MajorCategory'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Insurance Type", lang), Data: formObject['formValuesExtras']['InsuranceType'] },
      { Tag: 'End', Type: 'Section' },


      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("POLICY HOLDER", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Account Type", lang), Data: formObject['selectedClient']['AccountType'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card", lang), Data: formObject['selectedClient']['IdentityCard'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("First Name", lang), Data: formObject['selectedClient']['FirstName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Last Name", lang), Data: formObject['selectedClient']['LongDescription'] },
      { Tag: 'End', Type: 'Section' },


      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("INSURED PERSON", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card", lang), Data: formObject['formValues']['identityCard'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("First Name", lang), Data: formObject['formValues']['firstName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Last Name", lang), Data: formObject['formValues']['lastName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Nationality", lang), Data: formObject['formValuesExtras']['nationalityDescription'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Occupation", lang), Data: occupation },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Birth Date", lang), Data: (formObject['formValues']['birthDate'] == undefined) ? '' : datePipe.transform(formObject['formValues']['birthDate'], 'dd/MM/yyyy') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Telephone", lang), Data: formObject['formValues']['tel'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Mobile", lang), Data: formObject['formValues']['mob'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Fax", lang), Data: formObject['formValues']['fax'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Email", lang), Data: formObject['formValues']['email'] },
      /*      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: "Gender", Data: (this.f.gender.value == 'M') ? 'Male' : 'Female' },*/
      {
        Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Address", lang),
        Data: formObject['formValues']['postalCode'] + ', ' + formObject['formValues']['address'] + ', ' + formObject['formValues']['streetNo'] + ', ' + formObject['formValues']['district'] + ', ' + formObject['formValues']['country']
      },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("POLICY", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Starting Date", lang), Data: datePipe.transform(formObject['formValues']['policyStartingDate'], 'dd/MM/yyyy') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Expiry Date", lang), Data: datePipe.transform(formObject['formValues']['policyExpiryDate'], 'dd/MM/yyyy') },
      { Tag: 'End', Type: 'Section' },
      //-------------------------------------------------------------------------------------------------------------------------------//


      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Description of building", lang) },
      {
        Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '1. ' + await this.genericService.useTranslation("Full Address o f property to be Insured (floor, apartment no and postcode):", lang),
        Data: formObject['formValues']['postalCodeofProperty'] + ', ' + formObject['formValues']['addressofProperty'] + ', ' + formObject['formValues']['streetNoofProperty'] + ', ' + formObject['formValues']['districtofProperty'] + ', ' + formObject['formValues']['countryofProperty']
      },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Commercial Property", lang), Data: formObject['formValues']['commercialProperty'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '2. ' + await this.genericService.useTranslation("Construction of Walls / Roofs", lang) + ':', Data: formObject['formValues']['ConstructionOfWallsRoof'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Year of Construction:", lang), Data: formObject['formValues']['YearOfConstruction'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '3. ' + await this.genericService.useTranslation("Number of floors (Excluding basement):", lang), Data: formObject['formValues']['NumberOfFloors'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Χρήση Υποστατικών: Basement:", lang), Data: formObject['formValues']['Basement'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Ground Floor:", lang), Data: formObject['formValues']['GroundFloor'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Upper Floors:", lang), Data: formObject['formValues']['UpperFloors'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '4. ' + await this.genericService.useTranslation("Use of building:", lang), Data: formObject['formValues']['UseOfBuilding'] },

      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['commercialProperty'] == 'Yes') ? 'ALL' : 'NO', ColumnName: '5. ' + await this.genericService.useTranslation("In case of commercial use, state type of stored goods:", lang), Data: formObject['formValues']['CommercialUse'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['commercialProperty'] == 'Yes') ? 'ALL' : 'NO', ColumnName: '6. ' + await this.genericService.useTranslation("In case of Industrial use, give a brief description of use:", lang), Data: formObject['formValues']['IndustrialUse'] },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '7. ' + await this.genericService.useTranslation("Name of the Owner of the building:", lang), Data: formObject['formValues']['OwnerOfTheBuilding'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '8. ' + await this.genericService.useTranslation("If the building adjoins another building, what is the construction and of adjoining building?", lang), Data: formObject['formValues']['AnotherBuilding'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '9. ' + await this.genericService.useTranslation("What are the uses of neighborhood buildings?", lang), Data: formObject['formValues']['NeighborhoodBuildings'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '10. ' + await this.genericService.useTranslation("Are any dangerous goods stored in neighborhood buildings?", lang), Data: formObject['formValues']['DangerousGoodsStored'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '11. ' + await this.genericService.useTranslation("Is the building unoccupied for more than 30 days? If yes, state number of days.", lang), Data: formObject['formValues']['BuildingUnoccupied'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '12. ' + await this.genericService.useTranslation("Are there any fire prevention devices? If yes state.", lang), Data: formObject['formValues']['FirePreventionDevices'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '13. ' + await this.genericService.useTranslation("Distance of nearest Fire station:", lang), Data: formObject['formValues']['Distance'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['commercialProperty'] == 'Yes') ? 'ALL' : 'NO', ColumnName: '14. ' + await this.genericService.useTranslation("State type of locks on doors, showrooms and windows:", lang), Data: formObject['formValues']['StateType'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '15. ' + await this.genericService.useTranslation("Is there an installed alarm system?", lang), Data: formObject['formValues']['InstalledAlarmSystem'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '16. ' + await this.genericService.useTranslation("Has there been damage or loss or have you made a previous claim? If YES, give details:", lang), Data: formObject['formValues']['DamageOrLoss'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '17. ' + await this.genericService.useTranslation("Have you held insurance on the proposed risk? If YES, state name of company:", lang), Data: formObject['formValues']['ProposedRisk'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '18. ' + await this.genericService.useTranslation("Are there any other insurance that cover the building? If YES, please state the name of the company:", lang), Data: formObject['formValues']['CoverTheBuilding'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '19. ' + await this.genericService.useTranslation("Is your property mortgaged? If YES, is mortgage clause required?", lang), Data: formObject['formValues']['PropertyMortgaged'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['commercialProperty'] == 'Yes') ? 'ALL' : 'NO', ColumnName: '20. ' + await this.genericService.useTranslation("In case of Commercial insurance do you hold stock records and keep accounting books?", lang), Data: formObject['formValues']['CommercialInsurance'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '21. ' + await this.genericService.useTranslation("Do you hold other insurances with our Company?", lang), Data: formObject['formValues']['OtherInsurances'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '22. ' + await this.genericService.useTranslation("Additional Comments", lang), Data: formObject['formValues']['additionalComments'] },
      { Tag: 'End', Type: 'Section' },
      //-------------------------------------------------------------------------------------------------------------------------------//


      { Rows: 4, Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Property proposed for insurance", lang) },
      { Tag: 'Row', Type: 'SectionSplit4', Display: 'ALL', ColumnName: "", Data: await this.genericService.useTranslation("Sum Insured FIRE", lang), Data2: await this.genericService.useTranslation("Sum Insured THEFT", lang), Data3: await this.genericService.useTranslation("Fire Premium", lang), Data4: await this.genericService.useTranslation("Theft Premium", lang) },
      { Tag: 'Row', Type: 'SectionSplit4', Display: (formObject['formValues']['commercialProperty'] == 'No') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Building-Private Residence", lang), Data: formObject['formValues']['BuildingPrivate1'], Data2: formObject['formValues']['BuildingPrivate2'], Data3: formObject['formValues']['BuildingPrivate3'], Data4: formObject['formValues']['BuildingPrivate4'] },
      { Tag: 'Row', Type: 'SectionSplit4', Display: (formObject['formValues']['commercialProperty'] == 'No') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Content-Private Residence", lang), Data: formObject['formValues']['ContentPrivate1'], Data2: formObject['formValues']['ContentPrivate2'], Data3: formObject['formValues']['ContentPrivate3'], Data4: formObject['formValues']['ContentPrivate4'] },
      { Tag: 'Row', Type: 'SectionSplit4', Display: (formObject['formValues']['commercialProperty'] == 'No') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Precious Objects-Private Residence", lang), Data: formObject['formValues']['PreciousObjects1'], Data2: formObject['formValues']['PreciousObjects2'], Data3: formObject['formValues']['PreciousObjects3'], Data4: formObject['formValues']['PreciousObjects4'] },
      { Tag: 'Row', Type: 'SectionSplit4', Display: (formObject['formValues']['commercialProperty'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Building-Business", lang), Data: formObject['formValues']['BuildingBusiness1'], Data2: formObject['formValues']['BuildingBusiness2'], Data3: formObject['formValues']['BuildingBusiness3'], Data4: formObject['formValues']['BuildingBusiness4'] },
      { Tag: 'Row', Type: 'SectionSplit4', Display: (formObject['formValues']['commercialProperty'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Machinery-Equipment-Furniture", lang), Data: formObject['formValues']['MachineryEquipmentFurniture1'], Data2: formObject['formValues']['MachineryEquipmentFurniture2'], Data3: formObject['formValues']['MachineryEquipmentFurniture3'], Data4: formObject['formValues']['MachineryEquipmentFurniture4'] },
      { Tag: 'Row', Type: 'SectionSplit4', Display: (formObject['formValues']['commercialProperty'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Goods-Materials", lang), Data: formObject['formValues']['GoodsMaterials1'], Data2: formObject['formValues']['GoodsMaterials2'], Data3: formObject['formValues']['GoodsMaterials3'], Data4: formObject['formValues']['GoodsMaterials4'] },
      { Tag: 'Row', Type: 'SectionSplit4', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Items specifically insured", lang), Data: formObject['formValues']['ItemsSpecificallyInsured1'], Data2: formObject['formValues']['ItemsSpecificallyInsured2'], Data3: formObject['formValues']['ItemsSpecificallyInsured3'], Data4: formObject['formValues']['ItemsSpecificallyInsured4'] },
      { Tag: 'Row', Type: 'SectionSplit4', Display: 'ALL', ColumnName: await this.genericService.useTranslation("TOTAL", lang), Data: formObject['formValues']['Total1'], Data2: formObject['formValues']['Total2'], Data3: formObject['formValues']['Total3'], Data4: formObject['formValues']['Total4'] },
      { Tag: 'End', Type: 'Section' },
      /*
      { Display: true, ColumnName: "1. Is the vehicle in good condition?", Data: this.f.q1_.value },
      */

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Description of building", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/A Explosion", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_a_explosion'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/B Bush/Forest Fire", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_b_bushForestFire'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/C Earthquake", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_c_earthquake'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/D Burst Pipe", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_d_burstPipe'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/E Flood, Hurricane, Storm", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_e_burstHurricane'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/F Impact", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_f_impact'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/G Malicious Damage", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_g_maliciousDamage'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/H Riots and Strike", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_h_riotsAndStrike'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/I Aircraft Damage", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_i_aircraftDamage'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/J Short Circuit (Equipment and Value)", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_j_shortCircuit'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/K Show, Ice Hail", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_k_showIceHail'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/L Loss of Rent", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_l_lossOfRent'], lang) + ' (' + await this.genericService.useTranslation('Limit', lang) + ' : €' + formObject['formValues']['limit_oc_l_lossOfRent'] + ' )' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/M Clearance of Debris", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_m_clearanceOfDebris'], lang) + ' (' + await this.genericService.useTranslation('Limit', lang) + ' : €' + formObject['formValues']['limit_oc_m_clearanceOfDebris'] + ' )' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/N Replacement Expenses", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_n_replacementExpenses'], lang) + ' (' + await this.genericService.useTranslation('Limit', lang) + ' : €' + formObject['formValues']['limit_oc_n_replacementExpenses'] + ' )' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/O Architect & Survey Fee", lang) + ':', Data: await this.genericService.useTranslation(formObject['formValues']['oc_o_architectSurveyFee'], lang) + ' (' + await this.genericService.useTranslation('Limit', lang) + ' : €' + formObject['formValues']['limit_oc_o_architectSurveyFee'] + ' )' },
      { Tag: 'End', Type: 'Section' },


      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("", lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Additional Related Covers", lang), Data: await this.genericService.useTranslation("Insured Capital", lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['commercialProperty'] == 'No') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("OC/A Theft with Forcible Entry into private dwelling", lang), Data: formObject['formValues']['PrivateDwelling'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['commercialProperty'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("OC/B Theft with Forcible Entry into business premises", lang), Data: formObject['formValues']['BusinessPremises'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("OC/C Breakage of glass", lang), Data: formObject['formValues']['BreakageOfGlass'] },
      { Tag: 'End', Type: 'Section' },
    ]


    return values;
  } // end acceptancePage()




  async acceptancePage_medicalmalpracticeliabilityform22(formObject, lang) {

    var datePipe = new DatePipe("en-CY");

    //get certificate language
    //var lang = formObject['CertificateLanguage'];

    //get file



    //then from file we get the values

    let values = [
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product", lang), Data: formObject['product']['Title'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Major Category", lang), Data: formObject['product']['MajorCategory'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Insurance Type", lang), Data: formObject['product']['InsuranceType'] },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Proposers Details", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Account Type", lang), Data: formObject['formValuesExtras']['AccountTypeDescription'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity", lang), Data: formObject['formValues']['identityCard'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("First Name", lang), Data: formObject['formValues']['firstName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Last Name", lang), Data: formObject['formValues']['lastName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Tel.", lang), Data: formObject['formValues']['tel'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Mob.", lang), Data: formObject['formValues']['mob'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Email", lang), Data: formObject['formValues']['email'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Trading Name & Number (If different from above)", lang), Data: formObject['formValues']['tradingNameAndNumber'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Reg. No.", lang), Data: formObject['formValues']['regnomitroou'] },
      {
        Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Address", lang),
        Data: formObject['formValues']['postalCode'] + ', ' + formObject['formValues']['address'] + ', ' + formObject['formValues']['streetNo'] + ', ' + formObject['formValues']['district'] + ', ' + formObject['formValues']['country']
      },
      {
        Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Trading Address", lang),
        Data: formObject['formValues']['postalCodeTrading'] + ', ' + formObject['formValues']['addressTrading'] + ', ' + formObject['formValues']['streetNoTrading'] + ', ' + formObject['formValues']['districtTrading'] + ', ' + formObject['formValues']['countryTrading']
      },
      /*{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Id Card No."), Data: formObject['formValues']['idcardno'] },*/
      { Tag: 'End', Type: 'Section' },



      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("POLICY", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Starting Date", lang), Data: datePipe.transform(formObject['formValues']['policyStartingDate'], 'dd/MM/yyyy') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Expiry Date", lang), Data: datePipe.transform(formObject['formValues']['policyExpiryDate'], 'dd/MM/yyyy') },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'HTMLElement', Title: "div", BootstrapClass: 'html2pdf__page-break col-md-12 px-3', Style: "margin-bottom:10px" },
      { Tag: 'End', Type: 'HTMLElement', Title: "div", BootstrapClass: '' },

      /*      { Tag: 'Start', Type: 'Section', Title: "General Information", BootstrapClass: '', Style: 'color:red' },*/
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("General Information", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("1. Please state your specialization. If you are a Surgeon please give full details of the type of surgery performed.", lang), Data: (formObject['formValues']['moreInfo'] != undefined && formObject['formValues']['moreInfo'] != '') ? formObject['formValues']['doctorOccupations'] + ', ' + formObject['formValues']['moreInfo'] : formObject['formValues']['doctorOccupations'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("2. Do you receive a full indemnity from your principals? If YES, please give full details", lang), Data: formObject['formValues']['q_fullIndemnity'] + ' , ' + formObject['formValues']['qt_fullIndemnity'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("3. What is your Total Gross Annual income excluding income from the sales of goods? If new business please state estimate income for the forthcoming 12 months", lang), Data: formObject['formValues']['totalGrossAnnualIncome'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("4. Please state the approximate percentage breakdown of your work between the following categories and state whether you are employed or self-employed", lang), Data: formObject['formValuesExtras']['IsEmployed'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("1. The Proposers Private Practice", lang), Data: formObject['formValues']['perc_ppp_1'] + '%' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("2. Public Sector Hospitals, Homes", lang), Data: formObject['formValues']['perc_ppp_2'] + '%' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("3. Private Surgical Hospitals, Homes", lang), Data: formObject['formValues']['perc_ppp_3'] + '%' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("4. Private Non-Surgical Hospitals, Homes", lang), Data: formObject['formValues']['perc_ppp_4'] + '%' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("5. Patients Homes", lang), Data: formObject['formValues']['perc_ppp_5'] + '%' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("6. Other (Please specify)", lang), Data: formObject['formValues']['perc_ppp_6'] + '%' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("TOTAL", lang), Data: formObject['formValues']['perc_ppp_sum'] + '%' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("5. Has the Proposer or any member of staff involved in the treatment or care of patients been the subject of or convicted of any criminal offence (other that minor traffic offences), professional disciplinary proceedings or inquiries?", lang), Data: formObject['formValues']['q_involvedInTreatment'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_involvedInTreatment'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("5. Please give details", lang), Data: formObject['formValues']['qt_involvedInTreatment'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("6. Are you a member of any professional organization or registered with any self regulating body?", lang), Data: formObject['formValues']['q_professionalOrgan'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_professionalOrgan'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Reg.", lang), Data: formObject['formValues']['qt_reg'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_professionalOrgan'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Reg. Date", lang), Data: formObject['formValues']['qt_regDate'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_professionalOrgan'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Reg. Type", lang), Data: formObject['formValues']['qt_regType'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_professionalOrgan'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Date of first registration", lang), Data: formObject['formValues']['qt_dateOfFirstReg'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("7. Has membership of / registration with such organization / body ever been suspended, withdrawn amended or declined or had conditions attached?", lang), Data: formObject['formValues']['q_suspended'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("8. Have you ever engaged in a similar activity under a different name?", lang), Data: formObject['formValues']['q_differentName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("9. Have you ever been Insured for Medical Professional Liability?", lang), Data: formObject['formValues']['q_medicalProfessionalLiability'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_medicalProfessionalLiability'] == 'Yes') ? 'ALL' : 'NO', ColumnName: 'i. ' + await this.genericService.useTranslation("The name of the Insurance Company", lang), Data: formObject['formValues']['qt_nameInsuranceCompany'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_medicalProfessionalLiability'] == 'Yes') ? 'ALL' : 'NO', ColumnName: 'ii. ' + await this.genericService.useTranslation("The insurance period/s or the period/s of your membership", lang), Data: formObject['formValues']['qt_periodMembership'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_medicalProfessionalLiability'] == 'Yes') ? 'ALL' : 'NO', ColumnName: 'iii. ' + await this.genericService.useTranslation("The limits of liability provided (if applicable)", lang), Data: formObject['formValues']['qt_limitsOfLiability'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_medicalProfessionalLiability'] == 'Yes') ? 'ALL' : 'NO', ColumnName: 'iv. ' + await this.genericService.useTranslation("Do you wish retroactive cover (if yes from)", lang), Data: formObject['formValues']['qt_retroactiveCover'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: 'v. ' + await this.genericService.useTranslation("Has any application for this type of Insurance cover or membership of a Defence Body ever been", lang), Data: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: 'a. ' + await this.genericService.useTranslation("Declined", lang), Data: formObject['formValues']['q_s_declined'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: 'b. ' + await this.genericService.useTranslation("Cancelled", lang), Data: formObject['formValues']['q_s_cancelled'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: 'c. ' + await this.genericService.useTranslation("Required special terms", lang), Data: formObject['formValues']['q_s_requiredSpecialTerms'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['q_s_requiredSpecialTerms'] == 'Yes') ? 'ALL' : 'NO', ColumnName: 'c. ' + await this.genericService.useTranslation("If YES, please give full details", lang), Data: formObject['formValues']['qt_s_fullDetails'] },
      { Tag: 'End', Type: 'Section' },



    ];


    var modalListCount = 1;
    formObject['modalList'].forEach(async item => {

      values.push(
        { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("CLAIM", lang) + " : " + modalListCount, BootstrapClass: 'col-md-4 px-3' },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Date of Incident", lang), Data: datePipe.transform(item['dateOfIncident'], 'dd/MM/yyyy') },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Date of Claim", lang), Data: datePipe.transform(item['dateOfClaim'], 'dd/MM/yyyy') },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Amount Claimed", lang), Data: item['amountClaimed'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Amount Paid", lang), Data: item['amountPaid'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Amount Outstanding", lang), Data: item['amountOutstanding'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Details including nature of the allegations and details of Claimant", lang), Data: item['detailsOfAllegations'] },
        { Tag: 'End', Type: 'Section' },
      );

      modalListCount = modalListCount + 1;
    });

    values.push(
      { Tag: 'Start', Type: 'HTMLElement', Title: "div", BootstrapClass: 'html2pdf__page-break col-md-12 px-3', Style: "margin-bottom:10px" },
      { Tag: 'End', Type: 'HTMLElement', Title: "div", BootstrapClass: '' },
    );

    values.push(
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("PART", lang) + " 10 ii", BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Date of Circumstance / Complaint", lang), Data: formObject['formValues']['dateOfCircumstance'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Details including nature of the Complaint and details of the Complainant", lang), Data: formObject['formValues']['detailsOfComplaint'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("11. i) Have all of the above in question 9 been to your previous Underwriters", lang), Data: formObject['formValues']['q_previousUnderwriters'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("ii) Have all of the above been accepted by your previous Underwriters?", lang), Data: formObject['formValues']['q_acceptedPreviousUnderwriters'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("12. Please indicate which limit(s) of indemnity you require quotations for : Per event", lang), Data: formObject['formValues']['limitsOfIndemnity'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("In the aggregate", lang), Data: formObject['formValues']['inTheAggregate'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("13. Please indicate the amount you wish for an excess amount", lang), Data: formObject['formValues']['excessAmount'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("14. At which Medical / Dental School did you qualify?", lang), Data: formObject['formValues']['whichMedical'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("In what year?", lang), Data: formObject['formValues']['inWhatYear'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Degree obtained", lang), Data: formObject['formValues']['degreeObtained'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Please give details of any additional or post graduate qualifications", lang), Data: formObject['formValues']['postGraduateQualifications'] },
      { Tag: 'End', Type: 'Section' },
    );

    return await values;
  }


  async acceptancePage_motorform(formObject, lang) {
    //console.log(formObject['formValues']['VehicleDrivenByOwner'] == 'Yes');
    //return;
    //get the occupation and nationality codes
    var datePipe = new DatePipe("en-CY");


    //var occupation = lang == 'en' ? formObject['formValues']['occupationObj']['description'] : occupation['formValues']['occupationObj']['grDescription']

    //get certificate language
    //var lang = formObject['formValues']['certificateLanguage'];


    let values = [];



    values.push(



      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product", lang), Data: await this.genericService.useTranslation("MOTOR INSURANCE PROPOSAL FORM", lang) },
      /*{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product Description", lang), Data: formObject['product']['Description'] },*/
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Major Category", lang), Data: formObject['product']['MajorCategory'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Insurance Type", lang), Data: formObject['formValues']['insuranceType'] },
      /*{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Price", lang), Data: formObject['product']['Price'] },*/
      { Tag: 'End', Type: 'Section' },

    );

      //{ Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("POLICY HOLDER", lang), BootstrapClass: '' },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card", lang), Data: formObject['selectedClient']['IdentityCard'] },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("First Name", lang), Data: formObject['selectedClient']['FirstName'] },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Last Name", lang), Data: formObject['selectedClient']['LongDescription'] },
    //{ Tag: 'End', Type: 'Section' },


    if (formObject['formValuesExtras'] != undefined && formObject['formValuesExtras']['uniqueProductType'] != undefined
      && formObject['formValuesExtras']['uniqueProductType'] == 'UIA') {

      values.push(
        { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("UIA", lang), BootstrapClass: '' },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("UIA LOADING", lang), Data: await this.genericService.useTranslation("TEST LOADING", lang) },
        { Tag: 'End', Type: 'Section' },

      )

    }



    values.push(
      { Tag: 'Start', Type: 'Section', Title: formObject['formValues']["accountType"] == 'C' ? await this.genericService.useTranslation("INSURED COMPANY", lang) : await this.genericService.useTranslation("INSURED PERSON", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card", lang), Data: formObject['selectedClient']['IdentityCard'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("First Name", lang), Data: formObject['formValues']['firstName'] == null ? '' : formObject['formValues']['firstName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Last Name", lang), Data: formObject['formValues']['lastName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Nationality", lang), Data: formObject['formValues']['NationalitySyCode'][(lang == 'el' ? 'GrDescription' : 'Description')] },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Occupation", lang), Data: (formObject['selectedClient']['AccountType'] == 'P') ? formObject['formValues']['OccupationDescription'] :'' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Occupation", lang), Data: formObject['formValues']['OccupationSyCode'][(lang == 'el' ? 'GrDescription' : 'Description')] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Birth Date", lang), Data: datePipe.transform(formObject['formValues']['birthDate'], 'dd/MM/yyyy') == null ? '' : datePipe.transform(formObject['formValues']['birthDate'], 'dd/MM/yyyy') },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Licence Date", lang), Data: datePipe.transform(formObject['formValues']['ClientLicenceDate'], 'dd/MM/yyyy') == null ? '' : datePipe.transform(formObject['formValues']['ClientLicenceDate'], 'dd/MM/yyyy') },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Place of Birth", lang), Data: formObject['formValues']['PlaceOfBirth'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Mobile", lang), Data: formObject['formValues']['mob'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Email", lang), Data: formObject['formValues']['email']  },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Fax", lang), Data: formObject['formValues']['fax'] == undefined || formObject['formValues']['fax'] == null ? '' : formObject['formValues']['fax'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Gender", lang), Data: (formObject['formValuesExtras']['genderDescription'] == null) ? '' : lang == 'el' ? (formObject['formValuesExtras']['genderDescription'] == 'M' ? 'Άντρας' : 'Γυναίκα') : (formObject['formValuesExtras']['genderDescription'] == 'M' ? 'Male' : 'Female') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Address", lang), Data: formObject['formValues']['address'] + " " + formObject['formValues']['streetNo'] + ", " + formObject['formValues']['postalCode'] + " " + formObject['formValues']['district'] + " " + formObject['formValues']['country'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Existing Client", lang), Data: formObject['formValues']['existingClient'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Existing Client Details", lang), Data: formObject['formValues']['existingClientDetails'] },


      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("POLICY", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Starting Time", lang), Data: formObject['formValuesExtras']['policyStartingTime'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Starting Date", lang), Data: datePipe.transform(formObject['formValues']['policyStartingDate'], 'dd/MM/yyyy') },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Expiry Date", lang), Data: datePipe.transform(formObject['formValues']['policyExpiryDate'], 'dd/MM/yyyy') },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("COVER", lang), BootstrapClass: '' },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover ", lang), Data: await this.genericService.useTranslation(formObject['formValues']['cover'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Package", lang), Data: formObject['formValues']['coverPackage'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Excess", lang), Data: "€" + formObject['formValues']['excess'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Any Driver", lang), Data: (formObject['formValues']['anyDriver'] == '' || formObject['formValues']['anyDriver'] == undefined ? await this.genericService.useTranslation('No', lang) : await this.genericService.useTranslation(formObject['formValues']['anyDriver'], lang)) },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("PP 30-65", lang), Data: (formObject['formValues']['pp30_65'] == '' || formObject['formValues']['pp30_65'] == undefined ? 'No' : formObject['formValues']['pp30_65']) },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Reg No. or Chassis No.", lang), Data: await this.genericService.useTranslation(formObject['formValues']['regOrChassis'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Make", lang), Data: await this.genericService.useTranslation(formObject['formValues']['make'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Model", lang), Data: await this.genericService.useTranslation(formObject['formValues']['model'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Body Type", lang), Data: await this.genericService.useTranslation(formObject['formValues']['bodyType'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cubic Capacity", lang), Data: await this.genericService.useTranslation(formObject['formValues']['cubicCapacity'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("High Performance", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_4'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Electric Vehicle", lang), Data: await this.genericService.useTranslation(formObject['formValues']['isElectricVehicle'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Year of Manufacture", lang), Data: await this.genericService.useTranslation(formObject['formValues']['yearofManufacture'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Number of Passengers Incl. Driver", lang), Data: await this.genericService.useTranslation(formObject['formValues']['numberOfPassengers'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Colour", lang), Data: await this.genericService.useTranslation(formObject['formValues']['colour'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Type of Roof", lang), Data: await this.genericService.useTranslation(formObject['formValues']['typeOfRoof'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Drive Hand", lang), Data: await this.genericService.useTranslation(formObject['formValues']['steeringPosition'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Vehicle Value", lang), Data: (formObject['formValues']['vehicleValue'] == null ? '' : formObject['formValues']['vehicleValue']) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Vehicle Use", lang), Data: await this.genericService.useTranslation(formObject['formValues']['VehicleUse'], lang) },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("General Questions", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '1. ' + await this.genericService.useTranslation("Is the vehicle in good condition?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_1'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '2. ' + await this.genericService.useTranslation("Have any alterations been made to the original type of the vehicle? If so, give details", lang), Data: (formObject['formValues']['q_2'] == 'Yes') ? (await this.genericService.useTranslation(formObject['formValues']['q_2'], lang) + ", " + formObject['formValues']['qt_2']) : (await this.genericService.useTranslation(formObject['formValues']['q_2'], lang)) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '3. ' + await this.genericService.useTranslation("Has the vehicle been imported second hand? If so, state the country of origin", lang), Data: (formObject['formValues']['q_3'] == 'Yes') ? (await this.genericService.useTranslation(formObject['formValues']['q_3'], lang) + ", " + formObject['formValues']['qt_3']) : (await this.genericService.useTranslation(formObject['formValues']['q_3'], lang)) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '4. ' + await this.genericService.useTranslation("Is the vehicle sport or high performance?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_4'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '5. ' + await this.genericService.useTranslation("Is the vehicle duty paid?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_5'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '6. a)	' + await this.genericService.useTranslation("Do you or any other person who is driving the vehicle placed a claim against an Insurance Company or had an accident as a driver, during the past 5 years? If so, please provide details", lang), Data: (formObject['formValues']['q_6a'] == 'Yes') ? (await this.genericService.useTranslation(formObject['formValues']['q_6a'], lang) + ", " + formObject['formValues']['qt_6a']) : (await this.genericService.useTranslation(formObject['formValues']['q_6a'], lang)) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: 'b) ' + await this.genericService.useTranslation("Are you a entitled to a No Claim Bonus Discount?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_6b'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '7. ' + await this.genericService.useTranslation("Do you or any other person who is driving the motor vehicle, suffer from: diabetes, epilepsy, heart problems, reduced vision or hearing, or from any other disease or incapacity of body or mind? If so, give details", lang), Data: (formObject['formValues']['q_7'] == 'Yes') ? (await this.genericService.useTranslation(formObject['formValues']['q_7'], lang) + ", " + formObject['formValues']['qt_7']) : (await this.genericService.useTranslation(formObject['formValues']['q_7'], lang)) },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: '', BootstrapClass: 'position-relative col-md-12' },
      { Tag: 'Start', Type: 'HTMLText', Title: '<div style:"padding:10px;"><p> 8. ' + await this.genericService.useTranslation("Has any Insurance Company at any time for you or any person who will be driving the motor vehicle:", lang) + "</p></div>", BootstrapClass: 'ml-2' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '(i)	' + await this.genericService.useTranslation("Rejected any proposal for insurance?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_8a'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '(ii)	' + await this.genericService.useTranslation("Demanded increased premium or imposed special terms?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_8b'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '(iii)	' + await this.genericService.useTranslation("Refused to renew or cancelled any policy?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_8c'], lang) },
      { Tag: 'End', Type: 'HTMLText' },
      { Tag: 'End', Type: 'Section' },
      { Tag: 'Start', Type: 'Section', Title: '', BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '9. ' + await this.genericService.useTranslation("Do you have or have you ever had an insurance policy covering any motor vehicle; If so, state the name of Insurance Company ana policy number", lang), Data: (formObject['formValues']['q_9'] == 'Yes') ? (await this.genericService.useTranslation(formObject['formValues']['q_9'], lang) + ", " + formObject['formValues']['qt_9']) : (await this.genericService.useTranslation(formObject['formValues']['q_9'], lang)) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '10. ' + await this.genericService.useTranslation("Do you have any other policy with our company; If so, please give details", lang), Data: (formObject['formValues']['q_10'] == 'Yes') ? (await this.genericService.useTranslation(formObject['formValues']['q_10'], lang) + ", " + formObject['formValues']['qt_10']) : (await this.genericService.useTranslation(formObject['formValues']['q_10'], lang)) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '11. ' + await this.genericService.useTranslation("Is there a road tax licence in force?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_11'], lang) },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '12. ' + await this.genericService.useTranslation("Does the vehicle have an active M.O.T. check", lang), Data: (formObject['formValues']['q_12'] == 'Yes') ? ((await this.genericService.useTranslation(formObject['formValues']['q_12'], lang) + (formObject['formValues']['qt_12'].toString().trim() == '' ?'': (", " + datePipe.transform(formObject['formValues']['qt_12']), 'dd/MM/yyyy')))) : (await this.genericService.useTranslation(formObject['formValues']['q_12'], lang)) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '12. ' + await this.genericService.useTranslation("Does the vehicle have an active M.O.T. check?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_12'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '13. ' + await this.genericService.useTranslation("Owners name under a financing agreement (if any)", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_13'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '14. ' + await this.genericService.useTranslation("Please declare any material fact related to the proposed motor vehicle or drivers", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_14'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: '15. ' + await this.genericService.useTranslation("Agents comments / notes", lang), Data: await this.genericService.useTranslation(formObject['formValues']['q_15'], lang) },
      /// { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Drivers", lang), BootstrapClass: '' },

      { Tag: 'End', Type: 'Section' },
    );
    var driversListCount = 1;
    //if (formObject['formValues']['VehicleDrivenByOwner'] == 'Yes') {
    //  values.push({ Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Driver", lang) + " " + driversListCount, BootstrapClass: 'col-md-12 text-decoration-none' },
    //    { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Full Name", lang), Data: formObject['formValues']['firstName'] },
    //    { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Date of Birth", lang), Data: datePipe.transform(formObject['formValues']['birthDate'], 'dd/MM/yyyy') },
    //    { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Licence Date", lang), Data: datePipe.transform(formObject['formValues']['ClientLicenceDate'], 'dd/MM/yyyy') },
    //    { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card No.", lang), Data: formObject['formValues']['ClientLicenceDate'] },
    //    { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Full / Learner", lang) + " " + await this.genericService.useTranslation("Licence", lang), Data: await this.genericService.useTranslation((formObject['formValues']['isLearner']), lang) },
    //    { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Country of Origin", lang), Data: item['countryOfOrigin'] },
    //    { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Claims", lang), Data: item['claims'] },
    //    { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Conv. or Points", lang), Data: item['convOrPoints'] },
    //    { Tag: 'Row', Type: 'Section', Display: (item['convOrPoints'] > 0) ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Conv. or Points Date", lang), Data: datePipe.transform(item['convOrPoints'], 'dd/MM/yyyy') },
    //    { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Nationality", lang), Data: item['nationality'] },
    //    { Tag: 'End', Type: 'Section' },)
    //  driversListCount += 1;
    //}
    for (var item of formObject['formValues']['driversData']) {
      //console.log(item['fullOrLearner']);
      values.push(

        { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Driver", lang) + " " + driversListCount, BootstrapClass: 'col-md-12 text-decoration-none' },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Full Name", lang), Data: item['fullName'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Date of Birth", lang), Data: datePipe.transform(item['dateOfBirth'], 'dd/MM/yyyy') },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Licence Date", lang), Data: datePipe.transform(item['licenceDate'], 'dd/MM/yyyy') },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card No.", lang), Data: item['identityCardNo'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Full / Learner", lang) + " " + await this.genericService.useTranslation("Licence", lang), Data: await this.genericService.useTranslation((item['fullOrLearner']), lang) },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Country of Origin", lang), Data: item['countryOfOrigin'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Claims", lang), Data: item['claims'] },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Conv. or Points", lang), Data: item['convOrPoints'] },
        { Tag: 'Row', Type: 'Section', Display: (item['convOrPoints'] > 0) ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Conv. or Points Date", lang), Data: datePipe.transform(item['convOrPoints'], 'dd/MM/yyyy') },
        { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Nationality", lang), Data: item['nationality'] },
        { Tag: 'End', Type: 'Section' },
      );
      driversListCount++;
    }

    return values;
  }

  async acceptancePage_maxisafehomepolicyproposalform17(formObject, lang) {
    //get the occupation and nationality codes

    var datePipe = new DatePipe("en-CY");

    //console.log(formObject['formValues']['PortableItemsCoverRequired'] == "");
    if (formObject['formValues']['DobPersonal'].toString().trim() != '') {
      formObject['formValues']['DobPersonal'] = new Date(formObject['formValues']['DobPersonal']).toISOString().split('T')[0];
    }
    if (formObject['formValues']['DobSpouse'].toString().trim() != '') {
      formObject['formValues']['DobSpouse'] = new Date(formObject['formValues']['DobSpouse']).toISOString().split('T')[0];
    }
    //console.log(formObject['formValues']['PeriodWithoutInhibitant'])
    var occupation = await this.genericService.getOccupationByLanguage(lang, formObject['formValues']['OccupationSyCode']);
    occupation = lang == 'en' ? occupation[0]['Description'] : occupation[0]['GrDescription']
    //get certificate language
    //var lang = formObject['CertificateLanguage'];
    let values = [


      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("", lang), BootstrapClass: '' },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product", lang), Data: formObject['product']['Title'] },
      /* { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product Description", lang), Data: formObject['product']['Description'] },*/
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Major Category", lang), Data: formObject['product']['MajorCategory'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Insurance Type", lang), Data: formObject['product']['InsuranceType'] },
      /*{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Price", lang), Data: formObject['product']['Price'] },*/

      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("POLICY HOLDER", lang), BootstrapClass: '' },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("First Name", lang), Data: formObject['formValues']['firstName'] },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Surname", lang), Data: formObject['formValues']['lastName'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Identity Card", lang), Data: formObject['formValues']['identityCard'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Date of Birth", lang), Data: formObject['formValues']['birthDate'] == null ? '' : datePipe.transform(formObject['formValues']['birthDate'], "dd/MM/yyyy") },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Address", lang), Data: formObject['formValues']['address'] + " " + formObject['formValues']['streetNo'] + ", " + formObject['formValues']['postalCode'] + " " + formObject['formValues']['district'] + " " + formObject['formValues']['country'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Email", lang), Data: formObject['formValues']['email'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Occupation", lang), Data: occupation },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Telephone", lang), Data: formObject['formValues']['mob'] },


      { Tag: 'End', Type: 'Section' },
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Period of Insurance", lang), BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Starting Date", lang), Data: formObject['formValues']['policyStartingDate'] == null ? '' : datePipe.transform(formObject['formValues']['policyStartingDate'], "dd/MM/yyyy") },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Policy Expiry Date", lang), Data: formObject['formValues']['policyExpiryDate'] == null ? '' : datePipe.transform(formObject['formValues']['policyExpiryDate'], "dd/MM/yyyy") },
      { Tag: 'End', Type: 'Section' },
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("General Information", lang), BootstrapClass: '' },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Address of property to insured", lang), Data: (await this.genericService.useTranslation(formObject['formValues']['addressofProperty'], lang) + " " + await this.genericService.useTranslation(formObject['formValues']['streetNoofProperty'], lang) + ", " + await this.genericService.useTranslation(formObject['formValues']['postalCodeofProperty'], lang) + " " + await this.genericService.useTranslation(formObject['formValues']['districtofProperty'], lang) + " " + await this.genericService.useTranslation(formObject['formValues']['countryofProperty'], lang)) },
      /* { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product Description", lang), Data: formObject['product']['Description'] },*/
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Nature of construction", lang), Data: await this.genericService.useTranslation(formObject['formValues']['YearNatureOfConstruction'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Floors No.", lang), Data: await this.genericService.useTranslation(formObject['formValues']['FloorsNo'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Year of Construction", lang), Data: await this.genericService.useTranslation(formObject['formValues']['YearOfConstruction'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Has it been renovated?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['Renovation'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['Renovation'] == 'Yes' ? 'ALL' : 'NO'), ColumnName: await this.genericService.useTranslation("Renovation", lang), Data: formObject['formValues']['TypeOfRenovation'] },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Type of  Construction - Walls (external/internal)", lang), Data: await this.translateArray(formObject['formValues']['TypeOfConstWalls'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Type of  Construction - Roof", lang), Data: await this.translateArray(formObject['formValues']['TypeOfConstRoof'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Type of  Construction - Other", lang), Data: await this.genericService.useTranslation(formObject['formValues']['TypeOfConstOther'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Is the building in a good state of repair and will it be so maintained?", lang), Data: formObject['formValues']['BuildingRepairStateAndMaintenance'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Is your property showing signs of movement (i.e. cracking or bulging of walls) which could be attributable to subsidence, heave or landslip due to construction and/or location, or is it exposed to damage by storm of flood?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['SignsOfMovement'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['SignsOfMovement'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Details of movement signs", lang), Data: formObject['formValues']['SignsOfMovementDescription'] },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Signs Of Movement", lang), Data: await this.genericService.useTranslation(formObject['formValues']['SignsOfMovementDescription'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Is the house used only by you, your family and your domestic assistant?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['HouseUsagePeople'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Number of other tenants", lang), Data: await this.genericService.useTranslation(formObject['formValues']['NumberOfOtherTenants'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Total Days Without Inhibitant", lang), Data: formObject['formValues']['TotalDaysWithoutInhibitant'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Longest continuous Period Without Inhibitant", lang), Data: formObject['formValues']['PeriodWithoutInhibitant'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Is there any profession, bussiness or trade carried on the dwelling or in any portion of the premises of whitch the dwelling forms a part?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['BussinessDwelling'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Is there an alarm system installed and fully fuctioning in premises?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['InstalledAlarm'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Is there a fireplace in the house or a wood stove?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['FireplaceContained'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Are you insured or have you previously been insured in respect of any of the risks associated with this proposal?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['PreviousInsurances'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['PreviousInsurances'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Previous Insurances Company name, Policy Number", lang), Data: await this.genericService.useTranslation(formObject['formValues']['PreviousInsurancesCompNamePolicyNum'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Declined to get insured?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['DeclinedToInsure'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Required special terms to get insured?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['SpecialTermsToInsure'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Has any insurer or company cancelled or refused to renew your insurance?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['CancelledOrRefusedInsurance'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Has any insurer or company Increased your premium on renewal?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['IncreasedPremiumOnRenewal'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Have you ever sustained loss or damage or submitted a claim during the last 5 years in connection with any of the perils for which this insurance is to apply?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['SunstainedLossDamageOrClaimSubmitted'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['SunstainedLossDamageOrClaimSubmitted'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Loss, Damage or Submitted Claim details", lang), Data: await this.genericService.useTranslation(formObject['formValues']['SunstainedLossDamageOrClaimSubmittedDetails'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Have you or any member of your family, normally residing with you, evere been convicted of, or received a warning by the police authority, or changed with but not tried yet, for any offence other than driving one?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['ConvictedOrWarningAndNotTried'], lang) },

      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("BASIC COVERS", lang), BootstrapClass: '' },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("PACKAGE", lang), Data: await this.genericService.useTranslation(formObject['formValues']['PackageSelection'], lang) },
      /* { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Product Description", lang), Data: formObject['product']['Description'] },*/
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover Required", lang), Data: await this.genericService.useTranslation(formObject['formValues']['BuildingsCoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Sum Insured", lang), Data: "€" + await this.genericService.useTranslation(formObject['formValues']['SumInsured'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Does the sum insured amount represents the full cost of reconstruction of the building?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['AmountRepresentingReconstructionOfBuilding'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Please state whether you require coverage for breakage or collapse of television or radio antennas beyond the limit coverage provided by the package of your choice.", lang), Data: await this.genericService.useTranslation(formObject['formValues']['CollapseTvAntennasRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['CollapseTvAntennasRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Breakage of Television or collapse of Radio Antennas amount", lang), Data: "€" + await this.genericService.useTranslation(formObject['formValues']['BreakageTelevisionCollapseRadioAntennasAmount'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Is the building mortaged?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['MortagedBuilding'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['MortagedBuilding'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Mortage Bank", lang), Data: formObject['formValues']['MortagedBank'] },
      //{ Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Please indicate percentage of automatic inflation increase on Total Sum insured on renewal.", lang), Data: await this.genericService.useTranslation(formObject['formValues']['RenewalIncreasePercentage'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Percentage of automatic inflation increase on Total Sum insured on renewal", lang), Data: await this.genericService.useTranslation(formObject['formValues']['RenewalIncreasePercentage'], lang) },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover for architects fees", lang), Data: await this.genericService.useTranslation(formObject['formValues']['ArchitectsFeesCoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['ArchitectsFeesCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Amount for architects fees", lang), Data: "€" + formObject['formValues']['ArchitectsFeesCoverAmount'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover for removal of debris", lang), Data: await this.genericService.useTranslation(formObject['formValues']['RemovalDebrisCoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['RemovalDebrisCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Amount for removal of debris", lang), Data: "€" + formObject['formValues']['RemovalDebrisCoverAmount'] },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover for trace and access for damage to pipes", lang), Data: await this.genericService.useTranslation(formObject['formValues']['DamagedPipesCoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['DamagedPipesCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Amount for trace and access for damage to pipes", lang), Data: "€" + formObject['formValues']['DamagedPipesCoverAmount'] },

      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Contents", lang), BootstrapClass: '' },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover Required", lang), Data: await this.genericService.useTranslation(formObject['formValues']['ContentsCoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['ContentsCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Sum Insured", lang), Data: "€" + await this.genericService.useTranslation(formObject['formValues']['ContentsSectionSumInsured'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['ContentsCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Amount", lang) + " - " + await this.genericService.useTranslation("Content", lang), Data: "€" + await this.genericService.useTranslation(formObject['formValues']['ContentsSectionContentAmount'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['ContentsCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Amount", lang) + " - " + await this.genericService.useTranslation("Valuables", lang), Data: "€" + await this.genericService.useTranslation(formObject['formValues']['ContentsSectionValuablesAmount'], lang) },

      { Tag: 'End', Type: 'Section' },
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("OPTIONAL COVERS", lang), BootstrapClass: '' },
      { Tag: 'End', Type: 'Section' },
      //{ Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("Personal Money", lang), BootstrapClass: '' },
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("OC1 - Portable Items", lang), BootstrapClass: 'display-6 px-2em text-decoration-none' },
      { Tag: 'End', Type: 'Section' },
      { Tag: 'Start', Type: 'Section', Title: "", BootstrapClass: '' },
      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover Required", lang), Data: (formObject['formValues']['PortableItemsCoverRequired'] == "") ? await this.genericService.useTranslation("No", lang) : await this.genericService.useTranslation(formObject['formValues']['PortableItemsCoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['UnspecifiedPortableItem1'] != null && formObject['formValues']['UnspecifiedPortableItem1'] != undefined && formObject['formValues']['UnspecifiedPortableItem1'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Item", lang) + " 1", Data: await this.genericService.useTranslation(formObject['formValues']['UnspecifiedPortableItem1'], lang) + ", €" + formObject['formValues']['UnspecifiedPortableItem1Amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['UnspecifiedPortableItem1'] != null && formObject['formValues']['UnspecifiedPortableItem2'] != undefined && formObject['formValues']['UnspecifiedPortableItem2'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Item", lang) + " 2", Data: await this.genericService.useTranslation(formObject['formValues']['UnspecifiedPortableItem2'], lang) + ", €" + formObject['formValues']['UnspecifiedPortableItem2Amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['UnspecifiedPortableItem1'] != null && formObject['formValues']['UnspecifiedPortableItem3'] != undefined && formObject['formValues']['UnspecifiedPortableItem3'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Item", lang) + " 3", Data: await this.genericService.useTranslation(formObject['formValues']['UnspecifiedPortableItem3'], lang) + ", €" + formObject['formValues']['UnspecifiedPortableItem3Amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['PortableItemsCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Additional amount required?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc1AdditionalCoverRequired'], lang) + ((formObject['formValues']['oc1AdditionalCoverRequired'] == 'Yes') ? (", €" + formObject['formValues']['oc1AdditionalAmount']) : '') },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc1AdditionalItem1'] != null && formObject['formValues']['oc1AdditionalItem1'] != undefined && formObject['formValues']['oc1AdditionalItem1'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Additional Item", lang) + " 1", Data: await this.genericService.useTranslation(formObject['formValues']['oc1AdditionalItem1'], lang) + ", €" + formObject['formValues']['oc1AdditionalItem1Amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc1AdditionalItem2'] != null && formObject['formValues']['oc1AdditionalItem2'] != undefined && formObject['formValues']['oc1AdditionalItem2'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Additional Item", lang) + " 2", Data: await this.genericService.useTranslation(formObject['formValues']['oc1AdditionalItem2'], lang) + ", €" + formObject['formValues']['oc1AdditionalItem2Amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc1AdditionalItem3'] != null && formObject['formValues']['oc1AdditionalItem3'] != undefined && formObject['formValues']['oc1AdditionalItem3'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Additional Item", lang) + " 3", Data: await this.genericService.useTranslation(formObject['formValues']['oc1AdditionalItem3'], lang) + ", €" + formObject['formValues']['oc1AdditionalItem3Amount'] },
      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("OC2 - Personal Accident", lang), BootstrapClass: 'display-6 px-2em text-decoration-none' },
      { Tag: 'End', Type: 'Section' },
      { Tag: 'Start', Type: 'Section', Title: "", BootstrapClass: '' },


      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover Required", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2CoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2CoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Personal Cover Required", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2PersonalCoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Date of Birth", lang), Data: formObject['formValues']['DobPersonal'] == null ? '' : datePipe.transform(formObject['formValues']['DobPersonal'], 'dd-MM-yyyy') },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Occupation", lang), Data: await this.genericService.useTranslation(formObject['formValues']['OccupationPersonal'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2CoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Spouse Cover Required", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2SpouseCoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Date of Birth Spouse", lang), Data: formObject['formValues']['DobSpouse'] == null ? '' : datePipe.transform(formObject['formValues']['DobSpouse'], 'dd-MM-yyyy') },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Occupation Spouse", lang), Data: await this.genericService.useTranslation(formObject['formValues']['OccupationSpouse'], lang) },

      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Do you suffer from any eye, ear or heart illness or from epilepsy, paralysis, nervous disorder?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2SufferAnyIllness'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Are there any situations in your profession, your habits or pestimes which expose you to any special risk?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2ProffessionHabitsRisks'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Do you often travel abroad?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2OftenTravelAbroad'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Are you involved in any sport?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2InvolvedInSports'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Have any of your proposals for life, accident or medical insurance cover been rejected or accepted on special terms?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2RejectedOrSpecialAccepted'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Do you have any other accident insurance?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2OtherAccidentInsurance'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Have you ever submitted a claim or been paid compensation for bodily injury or sickness?", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2SubmittedClaimOrPaid'], lang) },


      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', Title: await this.genericService.useTranslation("Benefits", lang), BootstrapClass: '' },

      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Death", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2DeathAmount'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Loss of one or both yeas or one or more limbs", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2LossOfLimbsAmount'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Permanent total disability", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc2PermanentTotalDisabilityAmount'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Temporary total disability", lang), Data: "€50 - weekly" },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc2PersonalCoverRequired'] == 'Yes' || formObject['formValues']['oc2SpouseCoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Are all persons to be insured free from physical disability or incapacity?", lang), Data: formObject['formValues']['AllPersonsFreeFromDisability'] },

      { Tag: 'End', Type: 'Section' },

      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("OC3 - Accidental Damage (Electrical Equipment)", lang), BootstrapClass: '' },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover Required", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc3CoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['item1description'] != null && formObject['formValues']['item1description'] != undefined && formObject['formValues']['item1description'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Item", lang) + " 1", Data: formObject['formValues']['item1description'] + ", €" + formObject['formValues']['item1amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['item2description'] != null && formObject['formValues']['item2description'] != undefined && formObject['formValues']['item2description'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Item", lang) + " 2", Data: formObject['formValues']['item2description'] + ", €" + formObject['formValues']['item2amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['item3description'] != null && formObject['formValues']['item3description'] != undefined && formObject['formValues']['item3description'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Item", lang) + " 3", Data: formObject['formValues']['item3description'] + ", €" + formObject['formValues']['item3amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['item4description'] != null && formObject['formValues']['item4description'] != undefined && formObject['formValues']['item4description'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Item", lang) + " 4", Data: formObject['formValues']['item4description'] + ", €" + formObject['formValues']['item4amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['item5description'] != null && formObject['formValues']['item5description'] != undefined && formObject['formValues']['item5description'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Item", lang) + " 5", Data: formObject['formValues']['item5description'] + ", €" + formObject['formValues']['item5amount'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['item6description'] != null && formObject['formValues']['item6description'] != undefined && formObject['formValues']['item6description'].toString().trim() != '') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Item", lang) + " 6", Data: formObject['formValues']['item6description'] + ", €" + formObject['formValues']['item6amount'] },

      { Tag: 'End', Type: 'Section' },
      { Tag: 'Start', Type: 'Section', Title: await this.genericService.useTranslation("OC4 - Liability to your Domestic Employees", lang), BootstrapClass: '' },

      { Tag: 'Row', Type: 'Section', Display: 'ALL', ColumnName: await this.genericService.useTranslation("Cover Required", lang), Data: await this.genericService.useTranslation(formObject['formValues']['oc4CoverRequired'], lang) },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc4CoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Number of domestic employees", lang), Data: formObject['formValues']['oc4TotalDomesticEmployees'] },
      { Tag: 'Row', Type: 'Section', Display: (formObject['formValues']['oc4CoverRequired'] == 'Yes') ? 'ALL' : 'NO', ColumnName: await this.genericService.useTranslation("Employers Registration Number", lang), Data: formObject['formValues']['oc4EmployersRegNumber'] },


      { Tag: 'End', Type: 'Section' },
    ]
    return values;
  }
  //To translate an array and join with comma
  async translateArray(arrayToTranslate, lang) {
    // Extract the array from the form object


    // Map over each element in the array, translating each one
    const translatedArray = await Promise.all(arrayToTranslate.map(async (element) => {
      return await this.genericService.useTranslation(element, lang);
    }));

    // Join the translated elements with commas
    const translatedString = translatedArray.join(', ');

    return translatedString;
  }
}
