/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../components-global/menu-list-item/menu-list-item.component.ngfactory";
import * as i3 from "../components-global/menu-list-item/menu-list-item.component";
import * as i4 from "@angular/router";
import * as i5 from "../_services/userInfo.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../_services/receipt.service";
import * as i8 from "../_services/generic.service";
import * as i9 from "../_services/communication.service";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i12 from "@angular/material/list";
import * as i13 from "@angular/common";
import * as i14 from "./sidebar.component";
import * as i15 from "../_services/authentication.service";
import * as i16 from "../_services/system_values.service";
import * as i17 from "../_services/protector.service";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "navbar-brand"], ["style", "color:purple; font-size:30px; font-weight:bold; margin-left: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" (TEST DB)"]))], null, null); }
function View_SidebarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-list-item", [], [[1, "aria-expanded", 0]], null, null, i2.View_MenuListItemComponent_0, i2.RenderType_MenuListItemComponent)), i1.ɵdid(1, 4308992, null, 0, i3.MenuListItemComponent, [i4.Router, i1.ElementRef, i1.Renderer2, i5.UserInfoService, i6.TranslateService, i7.ReceiptService, i8.GenericService, i9.CommunicationService, i10.MatDialog], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).ariaExpanded; _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i11.View_MatNavList_0, i11.RenderType_MatNavList)), i1.ɵdid(1, 704512, null, 0, i12.MatNavList, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SidebarComponent_3)), i1.ɵdid(3, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navItems; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "sidebar-wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i13.ɵNgStyleImpl, i13.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i13.NgStyle, [i13.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-color": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "simple-text logo-mini"], ["href", "http://www.ydrogios.com.cy/"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "logo-image-small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["src", "assets/img/YPERA_logo_transparent.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "simple-text logo-normal"], ["href", "http://www.ydrogios.com.cy/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ypera Website "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i1.ɵdid(11, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_2)), i1.ɵdid(13, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (((_co.DBName == "YDROTEST") || (_co.DBName == "YDROTST2")) ? "lightblue" : "")); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.DBName == "YDROTEST") || (_co.DBName == "YDROTST2")); _ck(_v, 11, 0, currVal_1); var currVal_2 = (_co.authService.isLoggedIn && _co.usrInfo.isLoggedInDataComplete); _ck(_v, 13, 0, currVal_2); }, null); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sidebar-cmp", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i14.SidebarComponent, [i15.AuthenticationService, i5.UserInfoService, i16.SystemValuesService, i6.TranslateService, i17.ProtectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i1.ɵccf("sidebar-cmp", i14.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
